import "../../app.scss";
import React, { useState, useEffect } from "react";
import Banner from "./main-banner/Banner.js";
import Navbar from "./header/Navbar.js";

import { useWeb3React } from "@web3-react/core";
import useAuth from "../../hooks/useAuth";
import { useNavigate } from "react-router-dom";
function Landing() {
  const { account } = useWeb3React();
  const { login } = useAuth();
  let navigate = useNavigate();

  useEffect(() => {
    window.scrollTo(0, 0);
    let addrSign = localStorage.getItem("addrSign");

    if (account && addrSign) {
      navigate("/taskscreated");
    }
  }, [account]);

  const trustWallet = async () => {
    try {
      login("walletconnect");
      localStorage.setItem("connectorId", "walletconnect");
      localStorage.setItem("flag", "true");

      // navigate("/taskscreated");
    } catch (err) {
      console.log("eerr", err);
    }
  };

  const connectMetaMask1 = async () => {
    login("injected");
    localStorage.setItem("connectorId", "injected");
    localStorage.setItem("flag", "true");

    // navigate("/taskscreated");
  };

  return (
    <>
      <Navbar trustWallet={trustWallet} connectMetaMask1={connectMetaMask1} />
      <Banner trustWallet={trustWallet} connectMetaMask1={connectMetaMask1} />
    </>
  );
}

export default Landing;
