import React, { useEffect, useState } from "react";
import "./tasksdetails.scss";
import Modal from "react-bootstrap/Modal";
import Pagination from "react-bootstrap/Pagination";
import Navbar from "../landing/header/Navbar";
import ProgressBar from "react-bootstrap/ProgressBar";
import { Accordion } from "react-bootstrap";
import Footer from "../landing/footer/Footer";
import { useLocation } from "react-router-dom";
import axios from "axios";
import { API_URL } from "../../utils/ApiUrl";
import moment from "moment";
import BlanaceOfAccountTom from "../../hooks/dataFetchers/BlanaceOfAccountTom";
import { useWeb3React } from "@web3-react/core";
import { toast } from "react-toastify";

const Tasksdetails = () => {
  const val = localStorage.getItem("accessToken");
  const { account } = useWeb3React();

  const { balacefAccountTomi } = BlanaceOfAccountTom();
  const { search } = useLocation();
  const queryParams = new URLSearchParams(search);
  const disputeId = queryParams.get("disputeId");
  const taskId = queryParams.get("taskId");
  const [taskDetail, setTaskDetail] = useState({});
  const [creatorVoteData, setCreatorVoteData] = useState({});

  const [disputesData, setDisputesData] = useState([]);
  const [toVoteData, setToVoteData] = useState({});
  const [toVoteDispute, setToVoteDispute] = useState({});
  const [proofOfWork, setProofOfWork] = useState();
  const [tomiBalance, setTomiBalance] = useState("");
  const [error, setError] = useState("");

  const [page, setPage] = useState(0);
  const [limit, setLimit] = useState(10);
  const [totalCount, setTotalCount] = useState(0);
  const [pageCount, setPageCount] = useState(0);

  const [timerText, setTimerText] = useState("");

  const [timeshow, setTimeshow] = useState(false);
  const [day, setDay] = useState(0);
  const [hour, setHour] = useState(0);
  const [min, setMin] = useState(0);
  const [sec, setSec] = useState(0);

  useEffect(() => {
    const interval = setInterval(() => {
      const now = new Date();

      const coolDownEndTime = new Date(taskDetail?.coolDownPeriod); // Cooldown end time
      const votingEndTime = new Date(taskDetail?.votingEndPeriod); // Voting end time

      let targetTime, text;

      if (now < coolDownEndTime) {
        // Cooldown is active
        targetTime = coolDownEndTime;
        text = "Voting Starts Inn:";
      } else if (now < votingEndTime) {
        // Voting is active
        targetTime = votingEndTime;
        text = "Voting Ends In:";
      } else {
        // Both periods have ended
        clearInterval(interval);
        setTimeshow(true); // Time has ended
        return;
      }

      const diff = targetTime.getTime() - now.getTime();

      // Calculate remaining time
      const days = Math.floor(diff / (1000 * 60 * 60 * 24));
      const hours = Math.floor(
        (diff % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60)
      );
      const mins = Math.floor((diff % (1000 * 60 * 60)) / (1000 * 60));
      const secs = Math.floor((diff % (1000 * 60)) / 1000);

      // Update state with remaining time and current text
      setDay(days);
      setHour(hours);
      setMin(mins);
      setSec(secs);
      setTimerText(text);
    }, 1000);

    return () => clearInterval(interval); // Cleanup on unmount
  }, [taskDetail]);

  const [show, setShow] = useState(false);
  const [showFile, setShowFile] = useState(false);

  const handleClose = () => {
    setShow(false);
    setError("");
  };
  const handleShowFile = () => setShowFile(true);

  const handleCloseFile = () => {
    setShowFile(false);
    setProofOfWork();
  };
  const handleShow = () => setShow(true);

  const [show1, setShow1] = useState(false);

  const handleClose1 = () => {
    setShow1(false);
    setError("");
  };
  const handleShow1 = () => setShow1(true);

  const [show2, setShow2] = useState(false);

  const handleClose2 = () => setShow2(false);
  const handleShow2 = () => setShow2(true);

  const participantVotes = disputesData.reduce(
    (sum, dispute) => sum + (dispute.voteCount || 0),
    0
  );

  const creatorVotes =
    creatorVoteData?.winnerSelected === true
      ? creatorVoteData?.winnerVotes || 0
      : creatorVoteData?.creatorVotes || 0;
  const totalVotes = creatorVotes + participantVotes;
  // Calculate the percentage of creator votes

  const creatorVotePercentage =
    totalVotes > 0 ? ((creatorVotes / totalVotes) * 100).toFixed(2) : 0;

  const remainingPercentage =
    totalVotes > 0 ? (100 - creatorVotePercentage).toFixed(2) : 0;

  const handlePageChange = (selectedPage) => {
    setPage(selectedPage);
  };

  const getDisplayedRange = () => {
    const start = page * limit + 1;
    const end = Math.min((page + 1) * limit, totalCount);
    return `${start}-${end}`;
  };

  useEffect(() => {
    window.scrollTo(0, 0);
    GetTaskDetail();
    GetCreatorVoteList();
  }, [taskId]);

  useEffect(() => {
    GetDisputesList();
  }, [disputeId]);

  useEffect(() => {
    getTomiBalance();
    if (account) {
    }
  }, [account]);

  const getTomiBalance = async () => {
    let balance = await balacefAccountTomi();
    setTomiBalance(balance);
    if (balance) {
    }
  };

  const download = (e) => {
    const fileUrl = e; // Your file URL
    const fileName = "Task file"; // Desired file name
    window.open(fileUrl, "_blank");
    // Create an anchor element
    const link = document.createElement("a");
    // link.href = fileUrl;
    link.download = fileName;

    // Append the anchor to the document and trigger the download
    document.body.appendChild(link);
    link.click();

    // Clean up by removing the anchor from the DOM
    document.body.removeChild(link);
  };

  const VoteDisputes = async () => {
    setError("");

    var config = {
      method: "post",
      url: `${API_URL}votes`,
      headers: {
        Authorization: "Bearer " + val,
      },
      data: {
        // taskId: taskId,
        // userId: userIdTemp || toVoteData?.userId,
        taskDisputeId: toVoteDispute?.id,
        count: parseInt(tomiBalance),
      },
    };
    if (tomiBalance > 0) {
      await axios(config)
        .then(function (res) {
          const resData = res?.data?.data;
          toast.success(res?.data?.message);
          GetDisputesList();
          GetTaskDetail();
          GetCreatorVoteList();
          handleClose();
        })
        .catch(function (err) {
          toast.error(err?.response?.data?.message);

          throw err;
        });
    } else {
      setError("Not Enough Balance!");
    }
  };

  const VoteCreator = async () => {
    setError("");

    var config = {
      method: "post",
      url: `${API_URL}votes/creator-winner`,
      headers: {
        Authorization: "Bearer " + val,
      },
      data: {
        taskId: taskId,
        count: parseInt(tomiBalance),
      },
    };
    if (tomiBalance > 0) {
      await axios(config)
        .then(function (res) {
          const resData = res?.data?.data;
          toast.success(res?.data?.message);
          GetDisputesList();
          GetTaskDetail();
          GetCreatorVoteList();
          handleClose1();
        })
        .catch(function (err) {
          toast.error(err?.response?.data?.message);

          throw err;
        });
    } else {
      setError("Not Enough Balance!");
    }
  };

  const GetTaskDetail = async () => {
    var config = {
      method: "get",
      url: `${API_URL}tasks/${taskId}`,
      headers: {
        Authorization: "Bearer " + val,
      },
    };

    await axios(config)
      .then(function (res) {
        const resData = res?.data?.data;
        setTaskDetail(resData);
      })
      .catch(function (err) {
        throw err;
      });
  };

  const GetCreatorVoteList = async () => {
    var config = {
      method: "get",
      url: `${API_URL}tasks/${taskId}/creator-winner-disputes`,
      headers: {
        Authorization: "Bearer " + val,
      },
    };

    await axios(config)
      .then(function (res) {
        const resData = res?.data?.data;

        setCreatorVoteData(resData);
      })
      .catch(function (err) {
        throw err;
      });
  };

  const GetDisputesList = async () => {
    var config = {
      method: "get",
      url: `${API_URL}tasks/${taskId}/disputes?limit=10&offset=${page + 1}`,
      headers: {
        Authorization: "Bearer " + val,
      },
    };

    await axios(config)
      .then(function (res) {
        const resData = res?.data?.data;

        setDisputesData(resData?.tasks);
        setTotalCount(resData?.count || 0); // Set total disputes count
        setPageCount(Math.ceil((resData?.count || 0) / limit)); // Calculate total pages
      })
      .catch(function (err) {
        throw err;
      });
  };

  return (
    <>
      <Navbar />
      <section className="tasksdetails">
        <img
          src="/assets/importantassets/detailbackshade.png"
          alt="backshade"
          className="backshade"
        />
        <img
          src="/assets/importantassets/detailbackshadembl.png"
          alt="backshade"
          className="backshadembl d-none"
        />
        <div className="custom-container">
          <div className="detailmain">
            <div className="detailsleft">
              {/* <span className="statusmain">
                                <span className="statuscircle"></span>
                                <p className="statuspara">PARTICIPATED</p>
                            </span> */}
              <h4 className="detailhead">{taskDetail?.title}</h4>
              <p
                className="detailpara"
                dangerouslySetInnerHTML={{ __html: taskDetail?.description }}
              ></p>
              {/* <p className="detailpara">
                Sit aspernatur aut odit aut fugit, sed quia consequuntur magni
                dolores eos qui ratione voluptatem sequi nesciunt. Neque porro
                quisquam est, qui dolorem ipsum quia dolor sit amet,
                consectetur, adipisci velit, sed quia non.
              </p>
              <p className="detailpara">
                Numquam eius modi tempora incidunt ut labore et dolore magnam
                aliquam quaerat voluptatem. Ut enim ad minima veniam, quis
                nostrum exercitationem ullam corporis suscipit laboriosam, nisi
                ut aliquid ex ea commodi consequatur? Quis autem vel eum iure
                reprehenderit qui in ea voluptate velit esse quam nihil
                molestiae consequatur, vel illum qui dolorem eum fugiat quo
                voluptas nulla pariatur
              </p> */}
            </div>
            <div className="detailsright">
              <div className="innerdetailsright">
                <div className="detailtop">
                  <div className="detailinnerleft">
                    <p className="rewardpara">Reward</p>
                    <div className="rewardamount">
                      <div className="rewardimg">
                        <img
                          src={
                            taskDetail?.currencySymbol === "USDT"
                              ? "/assets/createtask/usdtT.svg"
                              : taskDetail?.currencySymbol === "ETH"
                              ? "/assets/createtask/eth.svg"
                              : taskDetail?.currencySymbol === "USDC"
                              ? "/assets/createtask/usdc.svg"
                              : ""
                          }
                          alt="innerrewardimg"
                          className="innerrewardimg"
                        />
                      </div>
                      {taskDetail?.reward} {taskDetail?.currencySymbol}
                    </div>
                  </div>
                  <div className="detailinnerright">
                    <p className="participantpara">Participants</p>
                    <div className="partcipantmainhead">
                      {taskDetail?.participants?.length > 0
                        ? taskDetail?.participants.slice(0 - 3)?.map((item) => {
                            return (
                              <div className="participantmain one">
                                <img
                                  src={
                                    item?.user?.imageUrl ||
                                    "/assets/dummyassets/dummythree.png"
                                  }
                                  alt="participantimg"
                                  className="participantimg"
                                />
                              </div>
                            );
                          })
                        : null}

                      {/* 
                      <div className="participantmain two">
                        <img
                          src="/assets/dummyassets/dummythree.png"
                          alt="participantimg"
                          className="participantimg"
                        />
                      </div>
                      <div className="participantmain three">
                        <img
                          src="/assets/dummyassets/dummythree.png"
                          alt="participantimg"
                          className="participantimg"
                        />
                      </div>
                      <div className="participantmain four">
                        <img
                          src="/assets/dummyassets/dummyfour.png"
                          alt="participantimg"
                          className="participantimg"
                        />
                      </div> */}
                      <div className="participantmain five">
                        {taskDetail?.participants?.length > 4 ? (
                          <p className="numberimages">
                            {taskDetail?.participants?.length - 3}+
                          </p>
                        ) : null}
                      </div>
                    </div>
                  </div>
                </div>
                <div className="maintimer">
                  {timeshow ? (
                    <div className="timerspans">Timer has ended!</div>
                  ) : (
                    <>
                      <p className="endpara">{timerText}</p>
                      <div className="timerspans">
                        <div className="maintime">
                          <div className="innertimer">
                            <span className="innertimertext">{day}D</span>
                          </div>
                        </div>
                        <p className="colonpara">:</p>
                        <div className="maintime">
                          <div className="innertimer">
                            <span className="innertimertext">{hour}H</span>
                          </div>
                        </div>
                        <p className="colonpara">:</p>
                        <div className="maintime">
                          <div className="innertimer">
                            <span className="innertimertext">{min}M</span>
                          </div>
                        </div>
                        <p className="colonpara">:</p>
                        <div className="maintime seconds">
                          <div className="innertimer">
                            <span className="innertimertext">{sec}S</span>
                          </div>
                        </div>
                      </div>
                    </>
                  )}
                </div>
              </div>
              <div className="votebox">
                <h2 className="mainheading">Current Votes</h2>
                <div className="votes">
                  {creatorVoteData?.winnerSelected == true ? (
                    <div className="parentvote">
                      {Object.keys(creatorVoteData).length !== 0 ? (
                        <>
                          <div className="parentleft">
                            <div className="innerleft">
                              <div className="mainimage">
                                <img
                                  src={
                                    creatorVoteData?.winner?.user?.imageUrl ||
                                    "/assets/dummyassets/dummythree.png"
                                  }
                                  alt="img"
                                  className=" innervoteimg"
                                />
                              </div>
                              <h6 className="texttttttttt">
                                {creatorVoteData?.winner?.user?.name}
                              </h6>
                            </div>
                            <div className="innerright">
                              <p className="creator"> Winner</p>
                            </div>
                          </div>
                          <div className="parentright">
                            <h6>{creatorVoteData?.winnerVotes || 0}</h6>
                          </div>
                        </>
                      ) : null}
                    </div>
                  ) : (
                    <div className="parentvote">
                      {Object.keys(creatorVoteData).length !== 0 ? (
                        <>
                          <div className="parentleft">
                            <div className="innerleft">
                              <div className="mainimage">
                                <img
                                  src={
                                    creatorVoteData?.creator?.imageUrl ||
                                    "/assets/dummyassets/dummythree.png"
                                  }
                                  alt="img"
                                  className=" innervoteimg"
                                />
                              </div>
                              <h6 className="texttttttttt">
                                {creatorVoteData?.creator?.name}
                              </h6>
                            </div>
                            <div className="innerright">
                              <p className="creator"> Creator</p>
                            </div>
                          </div>
                          <div className="parentright">
                            <h6>{creatorVoteData?.creatorVotes || 0}</h6>
                          </div>
                        </>
                      ) : null}
                    </div>
                  )}

                  {disputesData?.length > 0
                    ? disputesData.slice(0, 2).map((dispute, index) => (
                        <div className="parentvote" key={index}>
                          <div className="parentleft">
                            <div className="innerleft">
                              <div className="mainimage">
                                <img
                                  src={
                                    dispute?.user?.imageUrl ||
                                    "/assets/dummyassets/dummythree.png"
                                  }
                                  alt="img"
                                  className="innervoteimg"
                                />
                              </div>
                              <h6 className="texttttttttt">
                                {dispute?.user?.name
                                  ? dispute.user.name
                                  : `${dispute?.user?.walletAddress.slice(
                                      0,
                                      4
                                    )}...${dispute?.user?.walletAddress.slice(
                                      -3
                                    )}`}
                              </h6>
                            </div>
                            <div className="innerright">
                              <p className="creator">Participant</p>
                            </div>
                          </div>
                          <div className="parentright">
                            <h6>{dispute?.voteCount ?? 0}</h6>
                          </div>
                        </div>
                      ))
                    : null}
                </div>
                <div className="brdr"></div>
                <div className="cardprogress">
                  {/* <ProgressBar now={creatorVotePercentage} /> */}

                  <ProgressBar>
                    <ProgressBar
                      className="progress-creator"
                      now={creatorVotePercentage || 100}
                      key={1}
                    />
                    <ProgressBar
                      className="progress-remaining"
                      now={remainingPercentage}
                      key={2}
                    />
                  </ProgressBar>
                </div>
                <div className="lasttextpara">
                  <div className="innerrlast">
                    <div className="left">
                      <p>
                        <img
                          src="\assets\dummy\red.png"
                          alt="img"
                          className="img-fluid"
                        />
                        Refund to{" "}
                        {creatorVoteData?.winnerSelected
                          ? "Winner"
                          : "Vote for Creator"}
                      </p>
                    </div>
                    <div className="right">
                      {creatorVoteData?.winnerSelected == true ? (
                        <h6 className="votepara">
                          {creatorVoteData?.winnerVotes || 0}{" "}
                          <span>{creatorVotePercentage}%</span>
                        </h6>
                      ) : (
                        <h6 className="votepara">
                          {creatorVoteData?.creatorVotes || 0}{" "}
                          <span>{creatorVotePercentage}%</span>{" "}
                        </h6>
                      )}
                    </div>
                  </div>
                  <div className="innerrlast">
                    <div className="left">
                      <p>
                        <img
                          src="\assets\dummy\green.png"
                          alt="img"
                          className="img-fluid"
                        />
                        Refund to a Participant
                      </p>
                    </div>
                    <div className="right">
                      <h6 className="votepara">
                        {participantVotes}{" "}
                        <span>
                          {participantVotes == 0 ? 0 : remainingPercentage}%
                        </span>
                      </h6>
                    </div>
                  </div>
                </div>
              </div>

              {/* <p className="createdbypara">Created By</p>
                            <div className="creatormain">
                                <div className="creatormainimg">
                                    <img src="/assets/dummyassets/dummysix.png" alt="creatorimg" className="creatorimg" />
                                </div>
                                <p className="creatorwallet">0xa12s..14ws</p>
                            </div> */}
            </div>
          </div>
          <div className="votecateatortable">
            <h6 className="mainpartcipanthead">
              {creatorVoteData?.winnerSelected ? "Winner" : "Vote for Creator"}
            </h6>
            <div className="createdtasktable">
              <table>
                <thead>
                  <th>Name</th>
                  <th>No. of Votes</th>
                  <th>Vote</th>
                </thead>
                {creatorVoteData?.winnerSelected == true ? (
                  <tbody>
                    {Object.keys(creatorVoteData).length !== 0 ? (
                      <tr>
                        <td>
                          <p className="tablepara">
                            {creatorVoteData?.winner?.user?.name
                              ? creatorVoteData?.winner?.user?.name
                              : `${creatorVoteData?.winner?.user?.walletAddress.slice(
                                  0,
                                  4
                                )}...${creatorVoteData?.winner?.user?.walletAddress.slice(
                                  -3
                                )}`}
                          </p>
                        </td>
                        <td>
                          <p className="tablepara">
                            {creatorVoteData?.winnerVotes || 0}
                          </p>
                        </td>
                        <td>
                          {creatorVoteData?.hasVoted ? (
                            <button className="votebuttongreen">
                              <svg
                                xmlns="http://www.w3.org/2000/svg"
                                width="11"
                                height="12"
                                viewBox="0 0 11 12"
                                fill="none"
                              >
                                <path
                                  d="M9.8684 3.83714L4.36839 9.33714C4.33647 9.36911 4.29856 9.39446 4.25683 9.41176C4.2151 9.42906 4.17037 9.43796 4.12519 9.43796C4.08002 9.43796 4.03529 9.42906 3.99356 9.41176C3.95183 9.39446 3.91391 9.36911 3.88199 9.33714L1.47574 6.93089C1.41124 6.86639 1.375 6.77891 1.375 6.68769C1.375 6.59647 1.41124 6.50899 1.47574 6.44449C1.54024 6.37999 1.62772 6.34375 1.71894 6.34375C1.81016 6.34375 1.89764 6.37999 1.96214 6.44449L4.12519 8.60796L9.38199 3.35074C9.44649 3.28624 9.53397 3.25 9.62519 3.25C9.71641 3.25 9.80389 3.28624 9.8684 3.35074C9.9329 3.41524 9.96913 3.50272 9.96913 3.59394C9.96913 3.68516 9.9329 3.77264 9.8684 3.83714Z"
                                  fill="#3EDD1E"
                                />
                              </svg>
                              Vote
                            </button>
                          ) : new Date() >
                              new Date(taskDetail?.coolDownPeriod) &&
                            new Date() <
                              new Date(taskDetail?.votingEndPeriod) ? (
                            <button
                              className="votebutton"
                              onClick={() => {
                                setToVoteData(creatorVoteData);
                                handleShow1();
                              }}
                            >
                              Vote
                            </button>
                          ) : (
                            //  : new Date() >
                            //   new Date(taskDetail?.votingEndPeriod) ? (
                            //   <button className="votebutton">Vote</button>
                            // )

                            <button className="votebutton">Vote</button>
                          )}
                        </td>
                      </tr>
                    ) : (
                      <p>Creator Not Found</p>
                    )}
                  </tbody>
                ) : (
                  <tbody>
                    {Object.keys(creatorVoteData).length !== 0 ? (
                      <tr>
                        <td>
                          <p className="tablepara">
                            {creatorVoteData?.creator?.name
                              ? creatorVoteData?.creator?.name
                              : `${creatorVoteData?.creator?.walletAddress.slice(
                                  0,
                                  4
                                )}...${creatorVoteData?.creator?.walletAddress.slice(
                                  -3
                                )}`}
                          </p>
                        </td>
                        <td>
                          <p className="tablepara">
                            {creatorVoteData?.creatorVotes || 0}
                          </p>
                        </td>
                        <td>
                          {creatorVoteData?.hasVoted ? (
                            <button className="votebuttongreen">
                              <svg
                                xmlns="http://www.w3.org/2000/svg"
                                width="11"
                                height="12"
                                viewBox="0 0 11 12"
                                fill="none"
                              >
                                <path
                                  d="M9.8684 3.83714L4.36839 9.33714C4.33647 9.36911 4.29856 9.39446 4.25683 9.41176C4.2151 9.42906 4.17037 9.43796 4.12519 9.43796C4.08002 9.43796 4.03529 9.42906 3.99356 9.41176C3.95183 9.39446 3.91391 9.36911 3.88199 9.33714L1.47574 6.93089C1.41124 6.86639 1.375 6.77891 1.375 6.68769C1.375 6.59647 1.41124 6.50899 1.47574 6.44449C1.54024 6.37999 1.62772 6.34375 1.71894 6.34375C1.81016 6.34375 1.89764 6.37999 1.96214 6.44449L4.12519 8.60796L9.38199 3.35074C9.44649 3.28624 9.53397 3.25 9.62519 3.25C9.71641 3.25 9.80389 3.28624 9.8684 3.35074C9.9329 3.41524 9.96913 3.50272 9.96913 3.59394C9.96913 3.68516 9.9329 3.77264 9.8684 3.83714Z"
                                  fill="#3EDD1E"
                                />
                              </svg>
                              Vote
                            </button>
                          ) : new Date() >
                              new Date(taskDetail?.coolDownPeriod) &&
                            new Date() <
                              new Date(taskDetail?.votingEndPeriod) ? (
                            <button
                              className="votebutton"
                              onClick={() => {
                                setToVoteData(creatorVoteData);
                                handleShow1();
                              }}
                            >
                              Vote
                            </button>
                          ) : (
                            //  : new Date() >
                            //   new Date(taskDetail?.votingEndPeriod) ? (
                            //   <button className="votebutton">Vote</button>
                            // )

                            <button className="votebutton">Vote</button>
                          )}
                        </td>
                      </tr>
                    ) : (
                      <p>Creator Not Found</p>
                    )}
                  </tbody>
                )}
              </table>
            </div>
          </div>

          <h6 className="mainpartcipanthead">Vote for Participant</h6>
          <div className="createdtasktable formobilecretable">
            <table>
              <thead>
                <th>Name</th>
                <th>Date Participated</th>
                <th>Proof of Work</th>
                <th>No. of Votes</th>
                <th>Vote</th>
              </thead>
              <tbody>
                {disputesData?.length > 0 ? (
                  disputesData?.map((dispute, index) => {
                    return (
                      <>
                        <tr>
                          <td>
                            <p className="tablepara">
                              {" "}
                              {dispute?.user?.name
                                ? dispute?.user?.name
                                : `${dispute?.user?.walletAddress.slice(
                                    0,
                                    4
                                  )}...${dispute?.user?.walletAddress.slice(
                                    -3
                                  )}`}
                            </p>
                          </td>
                          <td>
                            <p className="tablepara">
                              {moment(dispute?.createdAt).format("DD/MM/YYYY")}
                            </p>
                          </td>
                          <td>
                            <button
                              className="viewpara"
                              onClick={() =>
                                // window.open(dispute?.proofOfWork[0], "_blank")
                                {
                                  handleShowFile();
                                  setProofOfWork(dispute);
                                }
                              }
                            >
                              View Files
                            </button>
                          </td>
                          <td>
                            <p className="tablepara">{dispute?.voteCount}</p>
                          </td>
                          <td>
                            {dispute?.hasVoted ? (
                              <button className="votebuttongreen">
                                <svg
                                  xmlns="http://www.w3.org/2000/svg"
                                  width="11"
                                  height="12"
                                  viewBox="0 0 11 12"
                                  fill="none"
                                >
                                  <path
                                    d="M9.8684 3.83714L4.36839 9.33714C4.33647 9.36911 4.29856 9.39446 4.25683 9.41176C4.2151 9.42906 4.17037 9.43796 4.12519 9.43796C4.08002 9.43796 4.03529 9.42906 3.99356 9.41176C3.95183 9.39446 3.91391 9.36911 3.88199 9.33714L1.47574 6.93089C1.41124 6.86639 1.375 6.77891 1.375 6.68769C1.375 6.59647 1.41124 6.50899 1.47574 6.44449C1.54024 6.37999 1.62772 6.34375 1.71894 6.34375C1.81016 6.34375 1.89764 6.37999 1.96214 6.44449L4.12519 8.60796L9.38199 3.35074C9.44649 3.28624 9.53397 3.25 9.62519 3.25C9.71641 3.25 9.80389 3.28624 9.8684 3.35074C9.9329 3.41524 9.96913 3.50272 9.96913 3.59394C9.96913 3.68516 9.9329 3.77264 9.8684 3.83714Z"
                                    fill="#3EDD1E"
                                  />
                                </svg>
                                Vote
                              </button>
                            ) : new Date() >
                                new Date(taskDetail?.coolDownPeriod) &&
                              new Date() <
                                new Date(taskDetail?.votingEndPeriod) ? (
                              <button
                                className="votebutton"
                                onClick={() => {
                                  setToVoteDispute(dispute);
                                  handleShow();
                                }}
                              >
                                Vote
                              </button>
                            ) : (
                              //  : new Date() >
                              //   new Date(taskDetail?.votingEndPeriod) ? (
                              //   <button className="votebutton">Vote</button>
                              // )

                              <button className="votebutton">Vote</button>
                            )}
                          </td>
                        </tr>
                      </>
                    );
                  })
                ) : (
                  <p>No disputes found.</p>
                )}
              </tbody>
            </table>
            <div className="paginationmain">
              <p className="pagination-text">
                SHOWING {getDisplayedRange()} OF {totalCount}
              </p>
              <Pagination>
                <Pagination.Prev
                  onClick={() => handlePageChange(Math.max(page - 1, 0))}
                  disabled={page === 0}
                />
                {Array.from({ length: pageCount }, (_, i) => (
                  <Pagination.Item
                    key={i}
                    active={i === page}
                    onClick={() => handlePageChange(i)}
                  >
                    {i + 1}
                  </Pagination.Item>
                ))}
                <Pagination.Next
                  onClick={() =>
                    handlePageChange(Math.min(page + 1, pageCount - 1))
                  }
                  disabled={page === pageCount - 1}
                />
              </Pagination>
            </div>
          </div>

          <div className="createdtaskmbl d-none">
            <div className="acctop">
              <p className="taskpara">Name</p>
              <p className="taskpara">No. of Votes</p>
            </div>

            <Accordion defaultActiveKey="0">
              {disputesData?.length > 0 ? (
                disputesData?.map((dispute, index) => {
                  return (
                    <>
                      <Accordion.Item eventKey="0">
                        <Accordion.Header>
                          <div className="taskmain">
                            <p className="innertaskpara">
                              {dispute?.user?.name
                                ? dispute?.user?.name
                                : `${dispute?.user?.walletAddress.slice(
                                    0,
                                    4
                                  )}...${dispute?.user?.walletAddress.slice(
                                    -3
                                  )}`}
                            </p>
                          </div>

                          <div className="taskmain">
                            <p className="innertaskpara">
                              {dispute?.voteCount}
                            </p>
                          </div>
                        </Accordion.Header>
                        <Accordion.Body>
                          <div className="mainacc">
                            <div className="inneracc">
                              <p className="leftacc">Date Participated</p>
                              <p className="rightacc">
                                {moment(dispute?.createdAt).format(
                                  "DD/MM/YYYY"
                                )}
                              </p>
                            </div>
                            <div className="inneracc">
                              <p className="leftacc">Proof of Work</p>
                              <p
                                className="rightacc forunderline"
                                onClick={() =>
                                  //   window.open(dispute?.proofOfWork[0], "_blank")
                                  {
                                    handleShowFile();
                                    setProofOfWork(dispute);
                                  }
                                }
                              >
                                View Files
                              </p>
                            </div>
                            <div className="inneracc">
                              <p className="leftacc">Dispute Created</p>
                              <p className="rightacc">15/10/2024</p>
                            </div>
                            <div className="inneracc">
                              {dispute?.hasVoted ? (
                                <button className="votebuttongreen">
                                  <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    width="11"
                                    height="12"
                                    viewBox="0 0 11 12"
                                    fill="none"
                                  >
                                    <path
                                      d="M9.8684 3.83714L4.36839 9.33714C4.33647 9.36911 4.29856 9.39446 4.25683 9.41176C4.2151 9.42906 4.17037 9.43796 4.12519 9.43796C4.08002 9.43796 4.03529 9.42906 3.99356 9.41176C3.95183 9.39446 3.91391 9.36911 3.88199 9.33714L1.47574 6.93089C1.41124 6.86639 1.375 6.77891 1.375 6.68769C1.375 6.59647 1.41124 6.50899 1.47574 6.44449C1.54024 6.37999 1.62772 6.34375 1.71894 6.34375C1.81016 6.34375 1.89764 6.37999 1.96214 6.44449L4.12519 8.60796L9.38199 3.35074C9.44649 3.28624 9.53397 3.25 9.62519 3.25C9.71641 3.25 9.80389 3.28624 9.8684 3.35074C9.9329 3.41524 9.96913 3.50272 9.96913 3.59394C9.96913 3.68516 9.9329 3.77264 9.8684 3.83714Z"
                                      fill="#3EDD1E"
                                    />
                                  </svg>
                                  Vote
                                </button>
                              ) : (
                                <button
                                  className="votebutton"
                                  onClick={() => {
                                    setToVoteData(dispute);

                                    handleShow();
                                  }}
                                >
                                  Vote
                                </button>
                              )}
                            </div>
                          </div>
                        </Accordion.Body>
                      </Accordion.Item>
                    </>
                  );
                })
              ) : (
                <p>No disputes found.</p>
              )}
            </Accordion>
            <div className="paginationmain">
              <Pagination>
                <Pagination.Prev
                  onClick={() => handlePageChange(Math.max(page - 1, 0))}
                  disabled={page === 0}
                />
                {Array.from({ length: pageCount }, (_, i) => (
                  <Pagination.Item
                    key={i}
                    active={i === page}
                    onClick={() => handlePageChange(i)}
                  >
                    {i + 1}
                  </Pagination.Item>
                ))}
                <Pagination.Next
                  onClick={() =>
                    handlePageChange(Math.min(page + 1, pageCount - 1))
                  }
                  disabled={page === pageCount - 1}
                />
              </Pagination>
            </div>
          </div>
        </div>
      </section>
      <Footer />

      {/* 
            <Modal className='tasksmodal' show={show} onHide={handleClose} centered>
                <Modal.Header closeButton>
                    <Modal.Title>Proof of Work</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div className='parenttext'>
                        <div className="workdetails">
                            <div className="workscroll">
                                <div className="workinner">
                                    <div className="proofleft">
                                        <div className="proofimg">
                                            <svg xmlns="http://www.w3.org/2000/svg" width="26" height="26" viewBox="0 0 26 26" fill="none">
                                                <path d="M3.10905 20.3537L3.08805 20.3747C2.80455 19.7552 2.62605 19.0517 2.55255 18.2747C2.62605 19.0412 2.82555 19.7342 3.10905 20.3537Z" fill="#FF0083" />
                                                <path d="M9.85001 11.2922C11.2302 11.2922 12.349 10.1733 12.349 8.79319C12.349 7.41303 11.2302 6.29419 9.85001 6.29419C8.46985 6.29419 7.35101 7.41303 7.35101 8.79319C7.35101 10.1733 8.46985 11.2922 9.85001 11.2922Z" fill="#FF0083" />
                                                <path d="M17.3995 2.49316H8.6005C4.7785 2.49316 2.5 4.77166 2.5 8.59366V17.3927C2.5 18.5372 2.6995 19.5347 3.088 20.3747C3.991 22.3697 5.923 23.4932 8.6005 23.4932H17.3995C21.2215 23.4932 23.5 21.2147 23.5 17.3927V14.9882V8.59366C23.5 4.77166 21.2215 2.49316 17.3995 2.49316ZM21.7885 13.5182C20.9695 12.8147 19.6465 12.8147 18.8275 13.5182L14.4595 17.2667C13.6405 17.9702 12.3175 17.9702 11.4985 17.2667L11.1415 16.9727C10.396 16.3217 9.2095 16.2587 8.3695 16.8257L4.4425 19.4612C4.2115 18.8732 4.075 18.1907 4.075 17.3927V8.59366C4.075 5.63266 5.6395 4.06816 8.6005 4.06816H17.3995C20.3605 4.06816 21.925 5.63266 21.925 8.59366V13.6337L21.7885 13.5182Z" fill="#FF0083" />
                                            </svg>
                                        </div>
                                        <p className="proofpara">Logo.jpg</p>
                                    </div>
                                    <div className="proofright">
                                        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                                            <path d="M11.88 14.9832C11.69 14.9832 11.5 14.9132 11.35 14.7632L8.79001 12.2032C8.50001 11.9132 8.50001 11.4332 8.79001 11.1432C9.08001 10.8532 9.56001 10.8532 9.85001 11.1432L11.88 13.1732L13.91 11.1432C14.2 10.8532 14.68 10.8532 14.97 11.1432C15.26 11.4332 15.26 11.9132 14.97 12.2032L12.41 14.7632C12.26 14.9132 12.07 14.9832 11.88 14.9832Z" fill="white" />
                                            <path d="M11.88 14.9132C11.47 14.9132 11.13 14.5732 11.13 14.1632V3.99316C11.13 3.58316 11.47 3.24316 11.88 3.24316C12.29 3.24316 12.63 3.58316 12.63 3.99316V14.1632C12.63 14.5732 12.29 14.9132 11.88 14.9132Z" fill="white" />
                                            <path d="M12 20.9232C6.85 20.9232 3.25 17.3232 3.25 12.1732C3.25 11.7632 3.59 11.4232 4 11.4232C4.41 11.4232 4.75 11.7632 4.75 12.1732C4.75 16.4432 7.73 19.4232 12 19.4232C16.27 19.4232 19.25 16.4432 19.25 12.1732C19.25 11.7632 19.59 11.4232 20 11.4232C20.41 11.4232 20.75 11.7632 20.75 12.1732C20.75 17.3232 17.15 20.9232 12 20.9232Z" fill="white" />
                                        </svg>
                                        <p className="downloadpara">Download</p>
                                    </div>
                                </div>
                                <div className="workinner">
                                    <div className="proofleft">
                                        <div className="proofimg">
                                            <svg xmlns="http://www.w3.org/2000/svg" width="26" height="26" viewBox="0 0 26 26" fill="none">
                                                <path d="M3.10905 20.3537L3.08805 20.3747C2.80455 19.7552 2.62605 19.0517 2.55255 18.2747C2.62605 19.0412 2.82555 19.7342 3.10905 20.3537Z" fill="#FF0083" />
                                                <path d="M9.85001 11.2922C11.2302 11.2922 12.349 10.1733 12.349 8.79319C12.349 7.41303 11.2302 6.29419 9.85001 6.29419C8.46985 6.29419 7.35101 7.41303 7.35101 8.79319C7.35101 10.1733 8.46985 11.2922 9.85001 11.2922Z" fill="#FF0083" />
                                                <path d="M17.3995 2.49316H8.6005C4.7785 2.49316 2.5 4.77166 2.5 8.59366V17.3927C2.5 18.5372 2.6995 19.5347 3.088 20.3747C3.991 22.3697 5.923 23.4932 8.6005 23.4932H17.3995C21.2215 23.4932 23.5 21.2147 23.5 17.3927V14.9882V8.59366C23.5 4.77166 21.2215 2.49316 17.3995 2.49316ZM21.7885 13.5182C20.9695 12.8147 19.6465 12.8147 18.8275 13.5182L14.4595 17.2667C13.6405 17.9702 12.3175 17.9702 11.4985 17.2667L11.1415 16.9727C10.396 16.3217 9.2095 16.2587 8.3695 16.8257L4.4425 19.4612C4.2115 18.8732 4.075 18.1907 4.075 17.3927V8.59366C4.075 5.63266 5.6395 4.06816 8.6005 4.06816H17.3995C20.3605 4.06816 21.925 5.63266 21.925 8.59366V13.6337L21.7885 13.5182Z" fill="#FF0083" />
                                            </svg>
                                        </div>
                                        <p className="proofpara">Logo.jpg</p>
                                    </div>
                                    <div className="proofright">
                                        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                                            <path d="M11.88 14.9832C11.69 14.9832 11.5 14.9132 11.35 14.7632L8.79001 12.2032C8.50001 11.9132 8.50001 11.4332 8.79001 11.1432C9.08001 10.8532 9.56001 10.8532 9.85001 11.1432L11.88 13.1732L13.91 11.1432C14.2 10.8532 14.68 10.8532 14.97 11.1432C15.26 11.4332 15.26 11.9132 14.97 12.2032L12.41 14.7632C12.26 14.9132 12.07 14.9832 11.88 14.9832Z" fill="white" />
                                            <path d="M11.88 14.9132C11.47 14.9132 11.13 14.5732 11.13 14.1632V3.99316C11.13 3.58316 11.47 3.24316 11.88 3.24316C12.29 3.24316 12.63 3.58316 12.63 3.99316V14.1632C12.63 14.5732 12.29 14.9132 11.88 14.9132Z" fill="white" />
                                            <path d="M12 20.9232C6.85 20.9232 3.25 17.3232 3.25 12.1732C3.25 11.7632 3.59 11.4232 4 11.4232C4.41 11.4232 4.75 11.7632 4.75 12.1732C4.75 16.4432 7.73 19.4232 12 19.4232C16.27 19.4232 19.25 16.4432 19.25 12.1732C19.25 11.7632 19.59 11.4232 20 11.4232C20.41 11.4232 20.75 11.7632 20.75 12.1732C20.75 17.3232 17.15 20.9232 12 20.9232Z" fill="white" />
                                        </svg>
                                        <p className="downloadpara">Download</p>
                                    </div>
                                </div>
                                <div className="workinner">
                                    <div className="proofleft">
                                        <div className="proofimg">
                                            <svg xmlns="http://www.w3.org/2000/svg" width="26" height="26" viewBox="0 0 26 26" fill="none">
                                                <path d="M3.10905 20.3537L3.08805 20.3747C2.80455 19.7552 2.62605 19.0517 2.55255 18.2747C2.62605 19.0412 2.82555 19.7342 3.10905 20.3537Z" fill="#FF0083" />
                                                <path d="M9.85001 11.2922C11.2302 11.2922 12.349 10.1733 12.349 8.79319C12.349 7.41303 11.2302 6.29419 9.85001 6.29419C8.46985 6.29419 7.35101 7.41303 7.35101 8.79319C7.35101 10.1733 8.46985 11.2922 9.85001 11.2922Z" fill="#FF0083" />
                                                <path d="M17.3995 2.49316H8.6005C4.7785 2.49316 2.5 4.77166 2.5 8.59366V17.3927C2.5 18.5372 2.6995 19.5347 3.088 20.3747C3.991 22.3697 5.923 23.4932 8.6005 23.4932H17.3995C21.2215 23.4932 23.5 21.2147 23.5 17.3927V14.9882V8.59366C23.5 4.77166 21.2215 2.49316 17.3995 2.49316ZM21.7885 13.5182C20.9695 12.8147 19.6465 12.8147 18.8275 13.5182L14.4595 17.2667C13.6405 17.9702 12.3175 17.9702 11.4985 17.2667L11.1415 16.9727C10.396 16.3217 9.2095 16.2587 8.3695 16.8257L4.4425 19.4612C4.2115 18.8732 4.075 18.1907 4.075 17.3927V8.59366C4.075 5.63266 5.6395 4.06816 8.6005 4.06816H17.3995C20.3605 4.06816 21.925 5.63266 21.925 8.59366V13.6337L21.7885 13.5182Z" fill="#FF0083" />
                                            </svg>
                                        </div>
                                        <p className="proofpara">Logo.jpg</p>
                                    </div>
                                    <div className="proofright">
                                        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                                            <path d="M11.88 14.9832C11.69 14.9832 11.5 14.9132 11.35 14.7632L8.79001 12.2032C8.50001 11.9132 8.50001 11.4332 8.79001 11.1432C9.08001 10.8532 9.56001 10.8532 9.85001 11.1432L11.88 13.1732L13.91 11.1432C14.2 10.8532 14.68 10.8532 14.97 11.1432C15.26 11.4332 15.26 11.9132 14.97 12.2032L12.41 14.7632C12.26 14.9132 12.07 14.9832 11.88 14.9832Z" fill="white" />
                                            <path d="M11.88 14.9132C11.47 14.9132 11.13 14.5732 11.13 14.1632V3.99316C11.13 3.58316 11.47 3.24316 11.88 3.24316C12.29 3.24316 12.63 3.58316 12.63 3.99316V14.1632C12.63 14.5732 12.29 14.9132 11.88 14.9132Z" fill="white" />
                                            <path d="M12 20.9232C6.85 20.9232 3.25 17.3232 3.25 12.1732C3.25 11.7632 3.59 11.4232 4 11.4232C4.41 11.4232 4.75 11.7632 4.75 12.1732C4.75 16.4432 7.73 19.4232 12 19.4232C16.27 19.4232 19.25 16.4432 19.25 12.1732C19.25 11.7632 19.59 11.4232 20 11.4232C20.41 11.4232 20.75 11.7632 20.75 12.1732C20.75 17.3232 17.15 20.9232 12 20.9232Z" fill="white" />
                                        </svg>
                                        <p className="downloadpara">Download</p>
                                    </div>
                                </div>
                                <div className="workinner">
                                    <div className="proofleft">
                                        <div className="proofimg">
                                            <svg xmlns="http://www.w3.org/2000/svg" width="26" height="26" viewBox="0 0 26 26" fill="none">
                                                <path d="M3.10905 20.3537L3.08805 20.3747C2.80455 19.7552 2.62605 19.0517 2.55255 18.2747C2.62605 19.0412 2.82555 19.7342 3.10905 20.3537Z" fill="#FF0083" />
                                                <path d="M9.85001 11.2922C11.2302 11.2922 12.349 10.1733 12.349 8.79319C12.349 7.41303 11.2302 6.29419 9.85001 6.29419C8.46985 6.29419 7.35101 7.41303 7.35101 8.79319C7.35101 10.1733 8.46985 11.2922 9.85001 11.2922Z" fill="#FF0083" />
                                                <path d="M17.3995 2.49316H8.6005C4.7785 2.49316 2.5 4.77166 2.5 8.59366V17.3927C2.5 18.5372 2.6995 19.5347 3.088 20.3747C3.991 22.3697 5.923 23.4932 8.6005 23.4932H17.3995C21.2215 23.4932 23.5 21.2147 23.5 17.3927V14.9882V8.59366C23.5 4.77166 21.2215 2.49316 17.3995 2.49316ZM21.7885 13.5182C20.9695 12.8147 19.6465 12.8147 18.8275 13.5182L14.4595 17.2667C13.6405 17.9702 12.3175 17.9702 11.4985 17.2667L11.1415 16.9727C10.396 16.3217 9.2095 16.2587 8.3695 16.8257L4.4425 19.4612C4.2115 18.8732 4.075 18.1907 4.075 17.3927V8.59366C4.075 5.63266 5.6395 4.06816 8.6005 4.06816H17.3995C20.3605 4.06816 21.925 5.63266 21.925 8.59366V13.6337L21.7885 13.5182Z" fill="#FF0083" />
                                            </svg>
                                        </div>
                                        <p className="proofpara">Logo.jpg</p>
                                    </div>
                                    <div className="proofright">
                                        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                                            <path d="M11.88 14.9832C11.69 14.9832 11.5 14.9132 11.35 14.7632L8.79001 12.2032C8.50001 11.9132 8.50001 11.4332 8.79001 11.1432C9.08001 10.8532 9.56001 10.8532 9.85001 11.1432L11.88 13.1732L13.91 11.1432C14.2 10.8532 14.68 10.8532 14.97 11.1432C15.26 11.4332 15.26 11.9132 14.97 12.2032L12.41 14.7632C12.26 14.9132 12.07 14.9832 11.88 14.9832Z" fill="white" />
                                            <path d="M11.88 14.9132C11.47 14.9132 11.13 14.5732 11.13 14.1632V3.99316C11.13 3.58316 11.47 3.24316 11.88 3.24316C12.29 3.24316 12.63 3.58316 12.63 3.99316V14.1632C12.63 14.5732 12.29 14.9132 11.88 14.9132Z" fill="white" />
                                            <path d="M12 20.9232C6.85 20.9232 3.25 17.3232 3.25 12.1732C3.25 11.7632 3.59 11.4232 4 11.4232C4.41 11.4232 4.75 11.7632 4.75 12.1732C4.75 16.4432 7.73 19.4232 12 19.4232C16.27 19.4232 19.25 16.4432 19.25 12.1732C19.25 11.7632 19.59 11.4232 20 11.4232C20.41 11.4232 20.75 11.7632 20.75 12.1732C20.75 17.3232 17.15 20.9232 12 20.9232Z" fill="white" />
                                        </svg>
                                        <p className="downloadpara">Download</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <button className='ok' onClick={() => {
                            handleClose();
                            handleShow1();
                        }}>SELECT WINNER</button>
                    </div>
                </Modal.Body>
            </Modal>

            <Modal className='tasksmodal' show={show1} onHide={handleClose1} centered>
                <Modal.Header closeButton>
                    <Modal.Title>Select Winner</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div className='parenttext'>
                        <div className='mainpic'>
                            <img src='/assets/importantassets/iimg.png' alt='img' className='img-fluid tick' />
                        </div>
                        <h2 className='heading'>Are you confident about your decision?</h2>
                        <p className='para'>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt.</p>
                        <div className="modalbtn">
                            <button onClick={handleClose1} className="cancelbtn">
                                CANCEL
                            </button>
                            <button onClick={() => {
                                handleClose1();
                                handleShow2();
                            }} className="surebtn">
                                YES I’M SURE
                            </button>
                        </div>
                    </div>
                </Modal.Body>
            </Modal>

            <Modal className='tasksmodal' show={show2} onHide={handleClose2} centered>
                <Modal.Body>
                    <div className='parenttext'>
                        <div className='mainpic'>
                            <img src='/assets/importantassets/tickimg.svg' alt='img' className='img-fluid tick' />
                        </div>
                        <h2 className='heading'>Winner Successfully Selected</h2>
                        <p className='para'>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam.</p>
                        <p className="winnerpara">
                            Winner:
                        </p>
                        <div className="winnermain">
                            <div className="winnermainimg">
                                <img src="/assets/dummyassets/dummyfour.png" alt="winnerimg" className="winnerimg" />
                            </div>
                            <p className="winnerparainner">0xa12s..14ws</p>
                        </div>
                        <button className='ok' onClick={handleClose2}>OK</button>
                    </div>
                </Modal.Body>
            </Modal> */}
      <Modal show={show} onHide={handleClose} className="modal_mainr" centered>
        <Modal.Header closeButton>
          <Modal.Title>Vote</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="modal4">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="70"
              height="70"
              viewBox="0 0 70 70"
              fill="none"
            >
              <rect
                x="5"
                y="5"
                width="60"
                height="60"
                stroke="#013159"
                stroke-width="2"
              />
              <rect x="34" y="18" width="2" height="28" fill="#013159" />
              <rect x="34" y="49" width="2" height="3" fill="#013159" />
            </svg>
            {/* <h1>Lorem ipsum dolor sit amet</h1>
            <p className="parssa">
              Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
              eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut
              enim ad minim veniam.
            </p> */}
          </div>
          <div className="mainscrooldiv2nd">
            <div className="inerscrooldiv2nd">
              <p className="dddooeenger">Vote For:</p>
              <div className="secdiv2nd">
                <img
                  src={
                    toVoteDispute?.user?.imageUrl ||
                    "/assets/dummyassets/dummyone.png"
                  }
                  className="inerimgg"
                />

                <p className="namewithimg">
                  {toVoteDispute?.user?.name !== null
                    ? toVoteDispute?.user?.name
                    : `${toVoteDispute?.user?.walletAddress.slice(
                        0,
                        4
                      )}...${toVoteDispute?.user?.walletAddress.slice(-3)}`}
                </p>
              </div>
            </div>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <div className="modal_footer">
            <button
              className="surebutton"
              onClick={() => {
                VoteDisputes();
              }}
            >
              Vote
            </button>
          </div>
          <div className="w-100">
            <small className="text-danger text-center d-block">{error}</small>
          </div>
          {/* <small className="text-danger ">{error}</small> */}
        </Modal.Footer>
      </Modal>

      <Modal
        show={show1}
        onHide={handleClose1}
        className="modal_mainr"
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title>Vote</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="modal4">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="70"
              height="70"
              viewBox="0 0 70 70"
              fill="none"
            >
              <rect
                x="5"
                y="5"
                width="60"
                height="60"
                stroke="#013159"
                stroke-width="2"
              />
              <rect x="34" y="18" width="2" height="28" fill="#013159" />
              <rect x="34" y="49" width="2" height="3" fill="#013159" />
            </svg>
            {/* <h1>Lorem ipsum dolor sit amet</h1>
            <p className="parssa">
              Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
              eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut
              enim ad minim veniam.
            </p> */}
          </div>
          <div className="mainscrooldiv2nd">
            {toVoteData?.winnerSelected ? (
              <div className="inerscrooldiv2nd">
                <p className="dddooeenger">Vote For:</p>
                <div className="secdiv2nd">
                  <img
                    src={
                      toVoteData?.winner?.user?.imageUrl ||
                      "/assets/dummyassets/dummyone.png"
                    }
                    className="inerimgg"
                  />
                  <p className="namewithimg">
                    {toVoteData?.winner?.user?.name !== null
                      ? toVoteData?.winner?.user?.name
                      : `${toVoteData?.winner?.user?.walletAddress.slice(
                          0,
                          4
                        )}...${toVoteData?.winner?.user?.walletAddress.slice(
                          -3
                        )}`}
                  </p>
                </div>
              </div>
            ) : (
              <div className="inerscrooldiv2nd">
                <p className="dddooeenger">Vote For:</p>
                <div className="secdiv2nd">
                  <img
                    src={
                      toVoteData?.creator?.imageUrl ||
                      "/assets/dummyassets/dummyone.png"
                    }
                    className="inerimgg"
                  />
                  <p className="namewithimg">
                    {toVoteData?.creator?.name !== null
                      ? toVoteData?.creator?.name
                      : `${toVoteData?.creator?.walletAddress.slice(
                          0,
                          4
                        )}...${toVoteData?.creator?.walletAddress.slice(-3)}`}
                  </p>
                </div>
              </div>
            )}
          </div>
        </Modal.Body>
        <Modal.Footer>
          <div className="modal_footer">
            <button
              className="surebutton"
              onClick={() => {
                VoteCreator();
              }}
            >
              Vote
            </button>
          </div>
          <div className="w-100">
            <small className="text-danger text-center d-block">{error}</small>
          </div>
          {/* <small className="text-danger ">{error}</small> */}
        </Modal.Footer>
      </Modal>

      <Modal
        show={showFile}
        onHide={handleCloseFile}
        className="modal_mainr"
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title>Proof of Work</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="modal3">
            <div className="subnmatouterdiv">
              <div className="">
                <p className="submitted">Submitted By</p>
              </div>

              <div className="secdiv">
                <img
                  src={
                    proofOfWork?.user?.imageUrl
                      ? proofOfWork?.user?.imageUrl
                      : "/assets/edituploadimg.png"
                  }
                  className="inerimgg"
                />
                <p className="namewithimg">
                  {proofOfWork?.user?.name
                    ? proofOfWork?.user?.name
                    : "anonymous"}
                </p>
              </div>
            </div>
            <div className="msgdiv">
              <h1 className="msghfdng">Message</h1>
              <p className="inermsgpars">
                {proofOfWork?.message ? proofOfWork?.message : ""}
              </p>
              <div className="mainscrooldiv">
                {proofOfWork?.proofOfWork?.map((e) => {
                  return (
                    <div className="inerscrooldiv">
                      <p className="dddooeenger">{e?.slice(-4)}</p>
                      <p
                        className="dddooeengerright"
                        onClick={() => {
                          download(e);
                        }}
                      >
                        <spam>
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="20"
                            height="20"
                            viewBox="0 0 20 20"
                            fill="none"
                          >
                            <path
                              d="M17.5 11.25V16.25C17.5 16.4158 17.4342 16.5747 17.3169 16.6919C17.1997 16.8092 17.0408 16.875 16.875 16.875H3.125C2.95924 16.875 2.80027 16.8092 2.68306 16.6919C2.56585 16.5747 2.5 16.4158 2.5 16.25V11.25C2.5 11.0842 2.56585 10.9253 2.68306 10.8081C2.80027 10.6908 2.95924 10.625 3.125 10.625C3.29076 10.625 3.44973 10.6908 3.56694 10.8081C3.68415 10.9253 3.75 11.0842 3.75 11.25V15.625H16.25V11.25C16.25 11.0842 16.3158 10.9253 16.4331 10.8081C16.5503 10.6908 16.7092 10.625 16.875 10.625C17.0408 10.625 17.1997 10.6908 17.3169 10.8081C17.4342 10.9253 17.5 11.0842 17.5 11.25ZM9.55781 11.6922C9.61586 11.7503 9.68479 11.7964 9.76066 11.8279C9.83654 11.8593 9.91787 11.8755 10 11.8755C10.0821 11.8755 10.1635 11.8593 10.2393 11.8279C10.3152 11.7964 10.3841 11.7503 10.4422 11.6922L13.5672 8.56719C13.6253 8.50912 13.6713 8.44018 13.7027 8.36431C13.7342 8.28844 13.7503 8.20712 13.7503 8.125C13.7503 8.04288 13.7342 7.96156 13.7027 7.88569C13.6713 7.80982 13.6253 7.74088 13.5672 7.68281C13.5091 7.62474 13.4402 7.57868 13.3643 7.54725C13.2884 7.51583 13.2071 7.49965 13.125 7.49965C13.0429 7.49965 12.9616 7.51583 12.8857 7.54725C12.8098 7.57868 12.7409 7.62474 12.6828 7.68281L10.625 9.74141V2.5C10.625 2.33424 10.5592 2.17527 10.4419 2.05806C10.3247 1.94085 10.1658 1.875 10 1.875C9.83424 1.875 9.67527 1.94085 9.55806 2.05806C9.44085 2.17527 9.375 2.33424 9.375 2.5V9.74141L7.31719 7.68281C7.19991 7.56554 7.04085 7.49965 6.875 7.49965C6.70915 7.49965 6.55009 7.56554 6.43281 7.68281C6.31554 7.80009 6.24965 7.95915 6.24965 8.125C6.24965 8.29085 6.31554 8.44991 6.43281 8.56719L9.55781 11.6922Z"
                              fill="#013159"
                            />
                          </svg>
                        </spam>
                        Download
                      </p>
                    </div>
                  );
                })}
              </div>
            </div>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <div className="modal_footer">
            {/* <button className="cancelbutton" onClick={handleClose2}>CANCEL</button> */}
            {/* <button className="surebutton" onClick={() => {
                            handleClose()
                            handleShow1()
                        }}>Approve Claim</button> */}
          </div>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default Tasksdetails;
