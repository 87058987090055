import "./app.scss";

import Footer from "./components/landing/footer/Footer.js";
import Landing from "./components/landing/Landing.js";
import Wardah from "./components/Wardah/Wardah.js";

import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import Taskscreated from "./components/Taskscreated/Taskscreated.js";
import Tasksdetails from "./components/Tasksdetails/Tasksdetails.js";

import Raza from "./components/Raza/Raza.js";

import useEagerConnect from "./hooks/useEagerConnect";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

function App() {
  useEagerConnect();
  return (
    <>
      <ToastContainer
        autoClose={5000}
        style={{ fontSize: 12, fontWeight: 300 }}
        theme="dark"
        position="top-center"
      />
      <Router>
        <Routes>
          <Route exact path="/" element={<Landing />} />
          <Route exact path="/taskscreated" element={<Taskscreated />} />
          <Route exact path="/tasksdetails" element={<Tasksdetails />} />

          <Route exact path="/raza" element={<Raza />} />
          <Route exact path="/wardah" element={<Wardah />} />
          {/* <Route exact path='/createtask' element={<Createtask />} /> */}
        </Routes>
        {/* <Footer /> */}
      </Router>
    </>
  );
}

export default App;
