import React, { useEffect, useRef } from 'react';
import './footer.scss';

const Footer = () => {

    return (
        <>
            <section className="main-footer" id='contactus'>
                <div className='custom-container'>
                    <div className='mainparentsss'>

                        <div className='mainimage'>
                            <img src='\assets\footericon.png' alt='img' className='img-fluid footorimg' />
                        </div>


                        <div className='bootom'>

                            <h6>© 2024 Tasks, All Rights Reserved</h6>
                            <p className='tomitext'>Built By<svg xmlns="http://www.w3.org/2000/svg" width="52" height="14" viewBox="0 0 52 14" fill="none">
                                <path d="M49.3366 0.779442C49.6443 0.471728 50.0112 0.317871 50.4372 0.317871C50.8633 0.317871 51.2302 0.471728 51.5379 0.779442C51.8456 1.08124 51.9995 1.44221 51.9995 1.86236C51.9995 2.28842 51.8456 2.65531 51.5379 2.96303C51.2302 3.27074 50.8633 3.4246 50.4372 3.4246C50.0112 3.4246 49.6443 3.27074 49.3366 2.96303C49.0289 2.65531 48.875 2.28842 48.875 1.86236C48.875 1.44221 49.0289 1.08124 49.3366 0.779442ZM49.159 4.64066H51.7243V13.5703H49.159V4.64066Z" fill="#FF0083" />
                                <path d="M43.8916 4.46338C44.9686 4.46338 45.8444 4.78884 46.519 5.43978C47.1996 6.09071 47.5398 6.95172 47.5398 8.0228V13.5705H44.9568V8.62639C44.9568 8.09973 44.8177 7.68254 44.5396 7.37482C44.2615 7.06711 43.8916 6.91325 43.4301 6.91325C42.9744 6.91325 42.5898 7.06415 42.2761 7.36595C41.9684 7.66183 41.8146 8.08197 41.8146 8.62639V13.5705H39.2493V8.62639C39.2493 8.09973 39.1103 7.68254 38.8321 7.37482C38.554 7.06711 38.1782 6.91325 37.7048 6.91325C37.2551 6.91325 36.8793 7.06119 36.5775 7.35707C36.2757 7.65295 36.1248 8.07606 36.1248 8.62639V13.5705H33.5596V4.64091H36.1248V5.71494C36.6988 4.88057 37.548 4.46338 38.6724 4.46338C39.8499 4.46338 40.6932 4.89832 41.2021 5.7682C41.4684 5.37764 41.8442 5.06401 42.3294 4.82731C42.8146 4.58469 43.3354 4.46338 43.8916 4.46338Z" fill="#FF0083" />
                                <path d="M27.6697 4.46338C28.3147 4.46338 28.9212 4.58173 29.4893 4.81843C30.0633 5.05514 30.5545 5.3806 30.9628 5.79483C31.377 6.20314 31.7025 6.69726 31.9392 7.27718C32.1818 7.85119 32.3031 8.46366 32.3031 9.11459C32.3031 9.98447 32.099 10.7745 31.6907 11.4846C31.2883 12.1947 30.732 12.7509 30.0219 13.1533C29.3177 13.5498 28.5336 13.748 27.6697 13.748C26.3501 13.748 25.2464 13.3072 24.3588 12.4255C23.4712 11.5438 23.0273 10.4401 23.0273 9.11459C23.0273 7.78905 23.4712 6.68247 24.3588 5.79483C25.2464 4.9072 26.3501 4.46338 27.6697 4.46338ZM27.6697 6.91325C27.0602 6.91325 26.5513 7.12333 26.1429 7.54347C25.7405 7.9577 25.5394 8.48141 25.5394 9.11459C25.5394 9.73002 25.7405 10.2478 26.1429 10.668C26.5513 11.0881 27.0602 11.2982 27.6697 11.2982C28.2674 11.2982 28.7703 11.0881 29.1787 10.668C29.587 10.2478 29.7911 9.73002 29.7911 9.11459C29.7911 8.48141 29.587 7.9577 29.1787 7.54347C28.7763 7.12333 28.2733 6.91325 27.6697 6.91325Z" fill="#FF0083" />
                                <path d="M22.5699 7.08172H20.3775V9.89552C20.3775 10.3571 20.5166 10.7033 20.7947 10.9341C21.0787 11.1648 21.4545 11.2802 21.922 11.2802C22.1705 11.2802 22.3865 11.2566 22.5699 11.2092V13.5703C22.2031 13.665 21.7918 13.7124 21.3361 13.7124C20.2651 13.7124 19.41 13.378 18.7709 12.7093C18.1318 12.0406 17.8122 11.1145 17.8122 9.93103V7.08172H16.1523V4.64072H17.8122V2.29736H20.3775V4.64072H22.5699V7.08172Z" fill="#FF0083" />
                                <path d="M6.68244 2.46922C9.09484 2.46922 11.0614 4.37684 11.1521 6.76506L13.2684 8.0634C13.3316 7.69441 13.3644 7.31545 13.3644 6.92842C13.3644 3.24139 10.373 0.252441 6.68197 0.252441C2.99089 0.252441 0 3.24186 0 6.92889C0 7.72718 0.140228 8.49318 0.397866 9.20218C0.652653 9.90549 1.02247 10.5532 1.48451 11.1241L3.38448 9.95821L5.57536 8.6138V13.7283H7.73154V8.57248L9.98421 9.95488L11.8837 11.1207C12.3453 10.5499 12.7146 9.90169 12.9689 9.19791L11.0276 8.00641L8.79632 6.63731L6.6872 5.34324L6.68244 5.34609L4.57808 6.63731L2.33871 8.01116C2.25363 7.66735 2.20847 7.3088 2.20847 6.93887C2.20847 4.4704 4.21158 2.46969 6.68197 2.46969L6.68244 2.46922ZM6.68244 7.92853L6.68672 7.93091L6.68244 7.93376V7.92806V7.92853Z" fill="#FF0083" />
                            </svg></p>
                        </div>

                    </div>

                </div>


            </section>
        </>
    )
}

export default Footer;
