import React from "react";
import { useState, useEffect } from "react";
import "./banner.scss";
import { Link } from "react-router-dom";
import axios from "axios";
import { API_URL } from "../../../utils/ApiUrl";
const Banner = ({ trustWallet, connectMetaMask1 }) => {
  const [data, setData] = useState(false);

  useEffect(() => {
    refreshTokeen();
  }, []);
  const refreshTokeen = async () => {
    let retoken = localStorage.getItem("refreshToken");
    var config = {
      method: "get",
      url: `${API_URL}analytics`,
    };

    await axios(config)
      .then(function (res) {
        setData(res.data.data);
        // setLoader(false)
      })
      .catch(function (err) {
        throw err;
      });
  };

  return (
    <>
      <section className="main-banner">
        <div className="custom-container">
          <div className="mainboxparent">
            <div className="mainbox">
              <h3>Connect Wallet</h3>
              <div className="twicebox">
                <div
                  className="innerbox"
                  onClick={connectMetaMask1}
                  style={{ cursor: "pointer" }}
                >
                  <div className="parenttext">
                    <div className="mainimage">
                      <img
                        src="\assets\metamask.svg"
                        alt="img"
                        className="metamask"
                      />
                    </div>
                    <p className="text">Metamask</p>
                  </div>
                </div>
                <div
                  className="innerbox"
                  onClick={trustWallet}
                  style={{ cursor: "pointer" }}
                >
                  <div className="parenttext">
                    <div className="mainimage">
                      <img
                        src="\assets\wallet.svg"
                        alt="img"
                        className="metamask"
                      />
                    </div>
                    <p className="text">WalletConnect</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default Banner;
