import { useCallback, useState } from "react";
import useWeb3 from "../useWeb3";
import { tomiContract } from "../../utils/contractHelpers";
import { useWeb3React } from "@web3-react/core";
import Environment from "../../utils/Environment";

const BalacefAccountTomi = () => {
  const { account } = useWeb3React();
  const web3 = useWeb3();
  const tokenAddress1 = Environment.tomi;
  const contract = tomiContract(tokenAddress1, web3);
  const balacefAccountTomi = useCallback(async () => {
    try {
      const buy = await contract.methods.balanceOf(account).call();
      console.log(buy, "buy in hook");

      return buy / 10 ** 18;
    } catch (error) {
      throw error;
    }
  }, [contract, account]);
  return { balacefAccountTomi: balacefAccountTomi };
};
export default BalacefAccountTomi;
