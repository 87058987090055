import React, { useEffect, useState } from "react";
import "./taskscreated.scss";
import Pagination from "react-bootstrap/Pagination";
import { Accordion } from "react-bootstrap";
import Navbar from "../landing/header/Navbar";
import Footer from "../landing/footer/Footer";
import { Link } from "react-router-dom";
import { API_URL } from "../../utils/ApiUrl";
import axios from "axios";
import moment from "moment/moment";
import { useNavigate } from "react-router-dom";
const Taskscreated = () => {
  const navigate = useNavigate(); // React Router hook for navigation
  const val = localStorage.getItem("accessToken");
  const [disputes, setDisputes] = useState([]);
  const [page, setPage] = useState(0);
  const [limit, setLimit] = useState(10);
  const [totalCount, setTotalCount] = useState(0);
  const [pageCount, setPageCount] = useState(0);

  useEffect(() => {
    window.scrollTo(0, 0);
    if (val && val !== null && val !== undefined) {
      GetDisputesData();
    } else {
      navigate("/");
    }
  }, [page]);

  const handleTitleClick = (id, taskId) => {
    navigate(`/tasksdetails?disputeId=${id}&taskId=${taskId}`); // Navigate with dispute ID as a route param
  };

  const handlePageChange = (selectedPage) => {
    setPage(selectedPage);
  };

  const getDisplayedRange = () => {
    const start = page * limit + 1;
    const end = Math.min((page + 1) * limit, totalCount);
    return `${start}-${end}`;
  };

  const GetDisputesData = async () => {
    var config = {
      method: "get",
      url: `${API_URL}tasks/disputes?limit=10&offset=${page + 1}`,
      headers: {
        Authorization: "Bearer " + val,
      },
    };

    await axios(config)
      .then(function (res) {
        const resData = res?.data?.data;
        console.log(resData);

        setDisputes(resData?.disputes);
        setTotalCount(resData?.count || 0); // Set total disputes count
        setPageCount(Math.ceil((resData?.count || 0) / limit)); // Calculate total pages
      })
      .catch(function (err) {
        throw err;
      });
  };
  return (
    <>
      <Navbar />
      <section className="taskscreated">
        <img
          src="/assets/importantassets/backshade.png"
          alt="backshade"
          className="backshade"
        />
        <img
          src="/assets/importantassets/backshadembl.png"
          alt="backshade"
          className="backshadembl d-none"
        />
        <div className="custom-container">
          <h2 className="createdhead">Disputes</h2>

          <div className="createdtasktable">
            <table>
              <thead>
                <th>Task Name</th>
                <th>Reward</th>
                <th>Date Ended</th>
                <th>Dispute Created</th>
                <th>Participants</th>
                <th>Status</th>
              </thead>
              <tbody>
                {disputes?.length > 0 ? (
                  disputes?.map((dispute, index) => {
                    return (
                      <>
                        <tr key={index}>
                          <td>
                            <p
                              className="taskpara clickable"
                              onClick={() =>
                                handleTitleClick(dispute?.id, dispute?.task?.id)
                              }
                              style={{
                                cursor: "pointer",
                                color: "#013159",
                                textDecoration: "underline",
                              }}
                            >
                              {dispute?.task?.title}
                            </p>
                          </td>
                          <td>
                            <p className="tablepara">
                              {dispute?.task?.reward} {dispute?.task?.currency}
                            </p>
                          </td>
                          <td>
                            <p className="tablepara">
                              {moment(dispute?.task?.endTime).format(
                                "DD/MM/YYYY"
                              )}
                            </p>
                          </td>
                          <td>
                            {" "}
                            <p className="tablepara">
                              {" "}
                              {moment(dispute?.createdAt).format("DD/MM/YYYY")}
                            </p>
                          </td>
                          <td>
                            <p className="tablepara">
                              {dispute?.task?.participantCount}
                            </p>
                          </td>
                          <td>
                            <p className="tablepara">
                              {dispute?.status == "inDispute" ? (
                                <span className="progressbtn">In Dispute</span>
                              ) : dispute?.status == "refunded" ? (
                                <span className="disputedbtn">Refunded</span>
                              ) : dispute?.status == "resolved" ? (
                                <span className="reviewbtn">Resolved</span>
                              ) : null}
                            </p>
                          </td>
                        </tr>
                      </>
                    );
                  })
                ) : (
                  <h6 className="nodata-text">No Disputes Found!</h6>
                )}
              </tbody>
            </table>
            <div className="paginationmain">
              <p className="pagination-text">
                SHOWING {getDisplayedRange()} OF {totalCount}
              </p>
              <Pagination>
                <Pagination.Prev
                  onClick={() => handlePageChange(Math.max(page - 1, 0))}
                  disabled={page === 0}
                />
                {Array.from({ length: pageCount }, (_, i) => (
                  <Pagination.Item
                    key={i}
                    active={i === page}
                    onClick={() => handlePageChange(i)}
                  >
                    {i + 1}
                  </Pagination.Item>
                ))}
                <Pagination.Next
                  onClick={() =>
                    handlePageChange(Math.min(page + 1, pageCount - 1))
                  }
                  disabled={page === pageCount - 1}
                />
              </Pagination>
            </div>
          </div>
          <div className="createdtaskmbl d-none">
            <div className="acctop">
              <p className="taskpara">Task name</p>
              <p className="taskpara">Status</p>
            </div>

            <Accordion defaultActiveKey="0">
              {disputes.length > 0 ? (
                disputes.map((dispute, index) => (
                  <>
                    <Accordion.Item eventKey="0">
                      <Accordion.Header>
                        <div className="taskmain">
                          <p className="innertaskpara">
                            {dispute?.task?.title}
                          </p>
                        </div>

                        <div className="statusmain">
                          {dispute?.status == "inDispute" ? (
                            <span className="progressbtn">In Dispute</span>
                          ) : dispute?.status == "refunded" ? (
                            <span className="disputedbtn">Refunded</span>
                          ) : dispute?.status == "resolved" ? (
                            <span className="reviewbtn">Resolved</span>
                          ) : null}
                        </div>
                      </Accordion.Header>
                      <Accordion.Body>
                        <div className="mainacc">
                          <div className="inneracc">
                            <p className="leftacc">Reward</p>
                            <p className="rightacc">
                              {dispute?.task?.reward}{" "}
                              {dispute?.task?.currencySymbol}
                            </p>
                          </div>
                          <div className="inneracc">
                            <p className="leftacc">Date Ended</p>
                            <p className="rightacc">
                              {" "}
                              {moment(dispute?.task?.endTime).format(
                                "DD/MM/YYYY"
                              )}
                            </p>
                          </div>
                          <div className="inneracc">
                            <p className="leftacc">Dispute Created</p>
                            <p className="rightacc">
                              {" "}
                              {moment(dispute?.createdAt).format("DD/MM/YYYY")}
                            </p>
                          </div>
                          <div className="inneracc">
                            <p className="leftacc">Participants</p>
                            <p className="rightacc">
                              {" "}
                              {dispute?.task?.participantCount}
                            </p>
                          </div>
                        </div>
                      </Accordion.Body>
                    </Accordion.Item>
                  </>
                ))
              ) : (
                <h6 className="nodata-text">No Disputes Found!</h6>
              )}
            </Accordion>
            <div className="paginationmain">
              <Pagination>
                <Pagination.Prev
                  onClick={() => handlePageChange(Math.max(page - 1, 0))}
                  disabled={page === 0}
                />
                {Array.from({ length: pageCount }, (_, i) => (
                  <Pagination.Item
                    key={i}
                    active={i === page}
                    onClick={() => handlePageChange(i)}
                  >
                    {i + 1}
                  </Pagination.Item>
                ))}
                <Pagination.Next
                  onClick={() =>
                    handlePageChange(Math.min(page + 1, pageCount - 1))
                  }
                  disabled={page === pageCount - 1}
                />
              </Pagination>
            </div>
          </div>
        </div>
      </section>
      <Footer />
    </>
  );
};

export default Taskscreated;
